@import '../styles/colortheme.scss';

:root {
    --cardheight: container.height;
}

.MyLoader_content {
    top: 50%;
    left: 50%;
    position: fixed;
}

.MyLoader_overlay {
    position: fixed !important;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.slick-prev:before,
.slick-next:before {
    color: #131313 !important;
    font-size: large;
}

.webHome {
    .services {
        .container {
            margin-top: 20px;
        }

        .row {
            .container {
                // box-shadow: $WebHomeBoxShadow;
                background-color: $WebHomeBackgroundolor;
                border-radius: 20px;
                padding: 20px;

                h2 {
                    font-size: 22px;
                    color: $nexusBlack;
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    width: 100%;
                    padding: 0px;
                }

                .row {
                    h6 {
                        margin-left: auto;
                        margin-right: auto;
                        text-align: center;
                    }
                }
            }
        }

        .servicehead {
            h1 {
                padding-top: 15px;
                font-size: 24px;
                color: $nexusBlue;
                margin-bottom: 0px;
            }
        }

        h6 {
            color: $nexusOrange;
            font-weight: 600;
        }

        .socialimage {
            img {
                width: 100px;
                margin-left: auto;
                margin-right: auto;
                vertical-align: top;
            }
        }

        .accordion-button {
            outline: none !important;
            margin-top: 10px;
            margin-bottom: 10px;
            position: relative;
            padding: 5px;
            color: rgb(49, 49, 49);
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            background-color: transparent !important;
        }

        .accordion-button:hover {
            color: $nexusBluelight;
        }

        .accordion-body {
            p {
                text-align: justify;
                font-size: 14px;
                color: $nexusBlue;
                line-height: 1.2;

                strong {
                    color: $nexusOrange;
                    font-size: 14px;
                }
            }
        }

        i {
            margin-right: 10px;
            margin-left: 10px;
            color: $nexusBlue;
            font-weight: lighter;
        }
    }

    .accordion-item,
    .accordion-item:focus,
    .accordion-item:active,
    .accordion-header,
    .accordion-header:focus,
    .accordion-header:active,
    .accordion-button:focus,
    .accordion-button:active {
        border: transparent !important;
        background-color: transparent !important;
    }

    .events {
        h2 {
            margin-bottom: 20px;
        }

        section {
            // display: flex;
            // flex-wrap: wrap;
            // gap: 15px;
            // justify-content: space-evenly;
            margin-top: 20px;
            padding-bottom: 40px;
            // width: 100%;

            // Container {
            //     margin: auto auto;
            // }

            .slick-list {
                overflow: visible;
            }

            .nexuscard {
                position: relative;
                display: flex;
                flex-direction: column;
                overflow-wrap: break-word;
                align-items: center;
                justify-content: flex-start;
                gap: 15px;
                width: 300px;
                height: auto;
                border: 1px solid $Allborder-color;
                background-color: $WebHomeBackgroundolor;
                border-radius: 20px;



                .card-img {
                    padding: 10px;
                }

                .card-text {
                    color: $labelTextColor;
                    font-size: 14px;
                    margin-top: 10px;


                    span {
                        color: black;
                    }

                    p {
                        font-size: 14px;
                        color: grey;
                        text-align: center;
                        margin: 0 0 5px 0;
                        font-weight: 500;
                    }
                }

                .card-title {
                    font-size: $subtitle-text-size;
                    color: $nexusBlue;
                    // padding: 5px;
                    text-align: center;
                    margin-bottom: 5px;
                }

                .card-body {
                    padding: 15px;
                }
            }

            @media screen and (max-width:700px) {
                .nexuscard {
                    width: 250px;
                    margin-left: auto;
                }
            }
        }
    }

    .titlesub {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: rgb(68, 68, 68);
    }

    .projwebdisplay {
        padding: 40px 0 40px 0;
        // border: 1px solid lightgray;
        border-radius: 15px;
        background-color: rgb(225 249 255);

        .container {
            @media (max-width:700px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: auto;
            }

            display: flex;
            justify-content: center;
            width: 90%;
            gap: 10px;
            padding: 5px;
            height: 180px;

            .projinfo {
                flex: 75%;
                padding: 5px;

                p {
                    color: black;
                    line-height: 1.5;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: justify;
                    margin-top: 16px;
                }
            }

            .projphoto {
                flex: 25%;

                img {
                    width: 300px;
                    margin-left: auto;
                    max-height: 150px;
                    padding: 0 10% 0 10%;
                }
            }
        }

    }

    .Mentors {
        .container {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            margin-left: auto;
            width: 90%;
            flex-wrap: wrap;
            gap: 30px;
        }

        .MentorImage {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: fit-content;

            img {
                width: 50%;
                border: 1px solid lightgray;
                border-radius: 10px;
                background-color: $WebHomeBackgroundolor;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 0px;

                span {
                    margin-top: 15px;
                    font-size: 16px;
                    font-weight: bold;
                    color: $label-text-color;
                }
            }

            button {
                margin-top: 10px;
                text-decoration: none;
                font-weight: 500;
                border: 1px solid lightgray;
                border-radius: 2px;

                &:hover {
                    background-color: rgba(214, 214, 214, 0.733);
                }
            }

        }

    }

    .modal {
        .modal-dialog {
            width: fit-content;

            .modal-content {
                .modal-header {
                    margin: auto 0;
                    border-bottom: none;
                    padding: 10px 10px 0 0;
                }

                .modal-body {
                    .modalmentors {
                        .container {
                            display: flex;
                            flex-direction: column;
                            gap: 15px;

                            h2 {
                                margin: 20px Auto;
                                color: $nexusBlue;
                                font-size: 26px;
                                text-align: center;
                            }

                            .mentorCard {
                                @media (max-width:700px) {
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    max-height: 500px;
                                }

                                display: flex;
                                border: 1px solid lightgrey;
                                border-radius: 7px;
                                padding: 15px;
                                gap: 15px;
                                max-height: 400px;

                                .leftCard {
                                    @media (max-width:700px) {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: start;
                                        align-items: center;
                                        max-width: 150px;
                                    }

                                    display: flex;
                                    flex-direction: column;
                                    justify-content: start;
                                    align-items: center;

                                    img {
                                        width: 150px;
                                        border-radius: 5px;
                                        background-color: $WebHomeBackgroundolor;
                                    }

                                    h3 {
                                        color: $labelTextColor;
                                        font-weight: bold;
                                        font-size: 20px;
                                        background-color: transparent;
                                    }
                                }

                                .rightCard {
                                    padding: 15px;
                                    overflow-y: auto;
                                    border-radius: 7px;
                                    max-height: 500px;

                                    p {
                                        @media (max-width:700px) {
                                            text-align: left;
                                            font-size: 14px;
                                            text-align: justify;
                                            line-height: 1.3;
                                        }

                                        font-weight: 400;
                                        font-size: 16px;
                                        line-height: 1.6;
                                        color: black;
                                        text-align: justify;

                                        span {
                                            @media (max-width:700px) {
                                                text-align: left;
                                                font-size: 14px;
                                                line-height: 1.3;
                                                text-align: justify;
                                            }

                                            font-weight: bold;
                                            font-size: 16px;
                                            color: $labelTextColor;
                                            text-align: justify;
                                        }
                                    }

                                    label {
                                        color: $nexusBlue;
                                    }

                                    ul {
                                        li {
                                            @media (max-width:700px) {
                                                text-align: left;
                                                font-size: 14px;
                                                line-height: 1.3;
                                            }

                                            span {
                                                font-weight: bold;
                                                font-size: 16px;
                                                color: $labelTextColor;
                                            }

                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }

    }

    #team-section {
        .container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: start;

            .card {
                display: flex;
                flex-direction: column;
                box-shadow: $WebHomeBoxShadow;
                // background-color: $WebHomeBackgroundolor;
                // border: 1px solid grey;

                width: 325px;
                height: 690px;
                margin: 10px;
                color: rgb(37, 37, 37);
                align-items: center;
                padding: 20px;
                border-radius: 20px;
                // outline: 0;

                .image {
                    border-radius: 20px;
                    width: 250px;
                    height: 280px;
                    margin-bottom: 10px;
                    background-color: $WebHomeBackgroundolor;

                }


                .title {
                    h5 {
                        font-family: 'Roboto', sans-serif;
                        color: $nexusBlue;
                        justify-content: center;
                        font-size: 22px;
                        font-weight: normal;
                        text-align: center;
                        margin-bottom: 0;
                    }

                    p {
                        text-align: center;
                        color: $nexusOrange;
                        font-size: 16px;
                        font-weight: normal;
                        margin: 0 10px 5px 10px;
                    }
                }

                .body {
                    p {
                        text-align: justify;
                        font-size: 16px;
                        font-weight: normal;
                        line-height: 1.4;
                        margin-bottom: 15px;
                        color: rgb(37, 37, 37);
                    }
                }
            }
        }
    }

    #aboutussection {
        padding-top: 70px;
    }

    .aboutus {
        .titlemain {
            padding-top: 0px !important;
        }

        margin-top: 60px;
        background-color: rgb(225, 249, 255);
        width: 100%;

        p {
            color: $nexusBlack;
            line-height: 1.7;
            font-size: 16px;
            font-weight: 400;
            text-align: justify;
            margin-top: 16px;
        }
    }


    .titlemain {
        padding-top: 40px;
        font-size: 26px;
        color: $nexusOrange;
        text-align: center;
    }

    #social-offering,
    #corporate-offering {
        h2 {
            font-size: 24px;
            font-weight: 600;
            color: $nexusBlue;
        }

        h3 {
            font-size: $subtitle-text-size !important;
            font-weight: 600;
            color: $subtitle-text-color !important;
            background-color: $subtitle-background;
            border-radius: $subtitle-radius;
            text-align: center !important;
            padding: 5px 0% 5px 0% !important;
            margin-bottom: 0px !important;
            margin-top: 0px !important;
        }

        h4 {
            margin-top: 40px;
            margin-bottom: 0px;
            color: rgb(124, 161, 204);
            font-size: $h4-text-size;
        }

        p {
            color: $small-text-color;
            font-size: $small-text-font-size;
            font-weight: $small-text-font-weight;
            text-align: justify;
        }
    }

}

.hero {
    position: relative;
}

.hero-overlay {
    margin-top: 5vw;
    padding-left: 2vw;
    text-align: left;
    position: absolute;
    z-index: 0;
    width: 100%;

    .scrollEventNotification {
        h1 {
            padding: 20px 0;
            background-color: transparent;
            font-size: 2vw;
            font-weight: 700;
            color: rgb(39, 39, 39);
            animation: marquee 12s linear infinite;

            @keyframes marquee {
                0% {
                    transform: translateX(90%)
                }

                100% {
                    transform: translateX(-100%);
                }
            }
        }

        @media screen and (max-width:600px) {
            h1 {
                font-size: 2.42vw;
                margin-top: 3vw;
            }
        }
    }

    h2 {
        font-size: 2.5vw;
        color: $nexusBlue;
        font-weight: normal;
        line-height: 2.1vw;
    }

    h3 {
        text-align: start;
        margin-top: 12vw;
        font-size: 2.8vw;
        color: $nexusOrange;
        font-weight: bold;
        line-height: 2.1vw;
    }

    h4 {
        font-size: 2.8vw;
        color: $nexusBlue;
        font-weight: normal;
        line-height: 2.1vw;
    }

    h6 {
        font-size: 1.5vw;
        font-weight: 400;
        line-height: 1.1vw;
    }

    hr {
        margin-top: 2vw;
        color: transparent;
    }

    p {
        margin-top: 1vw;
        font-size: 1.1vw;
    }

    .nexusButton {
        font-size: 1.5vw;
    }

    @media screen and (max-width:600px) {
        margin-top: 8vw;

        h1 {
            font-size: 3vw;
        }

        h3 {
            margin-top: 0;
        }

        h4 {
            line-height: 1.2vw;
        }
    }
}

.hero-image {
    // padding-top: 5vw;
    display: block;
    z-index: -1;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

@media screen and (max-width:600px) {
    .hero-image {
        padding-top: 15vw;
    }
}



.scrollTop {
    position: fixed;
    left: 50%;
    bottom: 20px;
    font-size: 40px;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.2;

    &:hover {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.5;
    }
}

.navbar-toggler {

    span {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        border: 1px solid lightgrey;
        border-radius: 3px;
    }
}


.navbar {
    width: 100%;
    align-items: end;
    background-color: white;

    .dropdown:hover {
        align-self: flex-start;
        // padding-left: 15px !important;
        padding-right: 5px !important;

    }

    .nav-link {
        color: $navbar-text-color !important;
        font-size: $navbar-text-size;
        // padding-left: 5px !important;
        // padding-right: 5px !important;


        &:hover {
            color: $button-background !important;
            font-weight: 700;
        }
    }

    a.dropdown-item {
        color: $navbar-text-color !important;
        font-size: $navbar-text-size;


        &:hover {
            background-color: transparent;
            color: $button-background !important;
            font-weight: 700;
        }
    }

    &-brand {
        @media screen and (max-width:600px) {
            font-size: 3vw;
        }

        font-size: 2vw;

        label {
            font-size: 10px;
            // color: $nexusBlue;
            margin-left: 5px;
        }
    }

    &-expand-lg {
        box-shadow: $WebHomeBoxShadow;

        .button {
            float: right;
        }
    }
}

.cardimg {
    margin-left: 10px;
    width: 15vw;
    align-items: center;
}

.cardimgsocial {
    @media screen and (max-width:600px) {
        width: 4vw;
    }

    margin: 0px 10px;
    max-width: 12vw;
    max-height: 4vw;
    align-items: center;
}

.cardimgNexus {
    @media screen and (max-width:600px) {
        width: 12vw;
    }

    width: 7vw;
    align-items: center;
    padding-bottom: 5px;
    padding-left: 3px;
}

h7 {
    vertical-align: middle;
}

/* Navbar Text END */

/* Event Cards START */
// .cardtitle {
//     font-size: 20px;
//     color: $nexusBlue;
//     font-weight: 400;
//     text-align: center;
// }

// .cardmain {
//     width: 100% !important;
//     border-radius: 50px;
//     padding: 10px;
//     margin-bottom: 30px;
//     box-shadow: $WebHomeBoxShadow;
// }

// summary {
//     outline: none;
//     border: 1px solid $Allborder-color;
//     border-radius: 10px;
//     cursor: pointer;
//     padding: 2px;
//     font-weight: 500;
// }

// .cardmain img {
//     max-width: 250px !important;
//     margin-left: auto;
//     margin-right: auto;
// }

/* Event Cards END */

// Social Media Start
#footer {
    background-color: rgb(211, 244, 255);
    width: 100%;
    margin-bottom: 10px;

    footer {
        a {
            color: rgb(0, 6, 41);
            font-size: 16px;
            text-align: center;
            text-decoration: none;
            margin-bottom: 10px;
            margin-right: 20px;
        }

        a:hover {
            font-size: 16px;
            font-weight: 700;
        }

        .row {
            p {
                padding-top: 10px;
                font-size: 16px;
                font-weight: 300px;
                vertical-align: middle;
                text-align: center;
                margin-bottom: 5px;
                // color: white;
            }

            .socialicons {
                text-align: center;
                margin-left: auto;
                margin-right: auto;


                span {
                    img {
                        margin-right: 10px;
                        border-radius: 50%;
                    }

                    img:hover {
                        transform: scale(0.90);
                        box-shadow: $WebHomeBoxShadow;
                        border-radius: 50%;
                    }
                }
            }
        }

        .cardimg {
            margin-left: auto;
            margin-right: auto;
            max-width: 35vw;
            height: 40px;
            align-items: center;
        }

        .termprivacy {
            display: flex;
            justify-content: center;

            a {
                // color: white;
            }

        }
    }
}

.terms {

    h1 {
        margin: 30px 0 30px 0;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        text-decoration: underline;
    }

    p {
        text-align: justify;
        font-size: 13px;
        font-weight: 500;

        span {
            font-weight: 700;
        }
    }

    h5 {
        font-size: 20px;
        background-color: rgb(0, 16, 36);
        color: white;
        padding: 3px 10px;
        margin-bottom: 10px 5px;

    }

    h6 {

        background-color: rgb(0, 55, 122);
        color: white;
        padding: 3px 10px;
        margin-bottom: 10px 5px;

    }



    ul {
        li {
            text-align: justify;
            font-size: 13px;
            font-weight: 500;

            li {
                list-style: lower-roman;
                counter-reset: 1;
            }
        }
    }

    ol {
        li {
            text-align: justify;
            font-size: 13px;
            font-weight: 500;
            list-style: circle;

            li {
                list-style: inside;
                counter-reset: reset;
            }
        }
    }
}

.notification {
    .noticard {
        .container {
            border: 1px solid lightgray;
            border-radius: 5px;
            position: relative;
            width: 100%;
            min-height: 70px;
            margin: 0 0 15px 10px;
            padding: 10px;

        }

        a {
            text-decoration: none;
            cursor: pointer;

            .circle {
                position: relative;
                left: -35px;
                top: 18px;
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $nexusBlue;
                // align-self: center;
                margin: -5px 0 5px 5px;

            }
        }

        .overlay {
            position: absolute;
            bottom: 0%;
            left: 0;
            width: 100%;
            height: 100%;
            // background: rgba(0, 0, 0, 0);
            transition: background 0.5s ease-in-out;
            z-index: 1;
        }

        .container:hover .overlay {
            display: block;
            background: rgba(0, 0, 0, 0.194);
        }


        .title {
            position: relative;
            margin-bottom: 2px;
            width: 100%;
            left: 0;
            top: 0px;
            font-weight: 700;
            font-size: 14px;
            text-align: left;
            color: $nexusBlue;
            z-index: 1;
        }

        .card-body {
            position: relative;

            p {
                margin-bottom: 2px;
                text-align: justify;
            }
        }

        .button {
            position: absolute;
            width: 100%;
            right: 0%;
            bottom: 0%;
            text-align: center;
            opacity: 0;
            transition: 1s ease-in;
            transition: opacity .5s ease-in;
            z-index: 2;
        }

        .container:hover .button {
            opacity: 1;
        }
    }
}