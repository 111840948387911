/* FontAwesome for working BootSnippet :> */

@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

// Styles
@import "../styles/_colortheme";
// @import "../styles/LoginHome";
// @import "../styles/_OwnProject";
// @import "../styles/_OwnProjects";
// @import "../styles/_OwnReports";
// @import "../styles/_OwnReport";
// @import "../styles/search";
// @import "../styles/_viewReport";
// @import "../styles/_microSite";
// @import "../styles/_ProjectInvites";
// @import "../styles/_pricing";
// @import "../styles/webHome";
// @import "../styles/createProject";
// @import "../styles/ProjectDetails";
// @import "../styles/registerUser";
// @import "../styles/loginWelcome";
@import "~bootstrap/scss/bootstrap";


* {
  padding: 0;
  margin: 0;
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.nexusButton {
  background-color: $nexusBlue  !important;
  border: none !important;
  border-radius: 5px;
  width: fit-content !important;
  height: fit-content !important;
  size: 10vw !important;
  padding: 2px 10px !important;
  color: white;
  margin: 5px 10px;

  &:hover {
    background-color: $nexusBlue  !important;
    color: black !important;
    transform: scale(.96);
  }
}

label{
  color: $labelTextColor;
  font-size: 15px;
  font-weight: 700;
}

p{
  color: black;
  font-weight: normal;
  font-size: 13px;
}

.list-group-item{
  outline: 0;
  border: 0;
}

.error {
  border-bottom: 1px solid #ee1d43 !important;
}

.errortext {
  color: #ee1d43;
}

.StyletoCheck {
  .errortext {
    color: #ee1d43;
  }


  // .projectexport {
  //   padding-top: 70px;
  // }

  // .error {
  //   border-bottom: 1px solid #ee1d43 !important;
  // }

  // .input-group-text {
  //   padding: 0px 5px 0px 0px;
  //   height: 25px;
  //   font-size: 13px;
  // }

  // .waves-effect {
  //   font-size: 20px;
  //   cursor: pointer;
  //   display: inline-block;
  //   overflow: hidden;
  //   -webkit-user-select: none;
  //   -moz-user-select: none;
  //   -ms-user-select: none;
  //   user-select: none;
  //   -webkit-tap-highlight-color: transparent;
  //   vertical-align: middle;
  //   will-change: opacity, transform;
  //   transition: 0.3s ease-out;
  //   color: $nexusOrange;
  // }

  // Social Media End

  // header .navbar {
  //   margin-bottom: 0;
  // }

  // .startdate .row {
  //   margin-bottom: 0px !important;
  // }
}