// Theme Colors
$nexusBlue: rgb(0, 141, 208);
$nexusBluelight: rgba(0, 142, 208, 0.6);
$nexusOrange: rgb(230, 101, 37);
$nexusOrangelight: rgba(230, 101, 37, 0.6);

$labelTextColor:rgb(60, 60, 60);
$Allborder-color: rgb(141, 141, 141);

$WebHomeBoxShadow:  rgba(188, 188, 188, 0.286) 0 0 10px 2px;
$WebHomeBackgroundolor:rgba(230, 230, 230, 0.568);

$darkGradientColor: rgb(185, 218, 255);
$midGradientColor: rgb(255, 255, 255);
$lightGradientColor: rgb(255, 255, 255);
$nexusBlack: rgb(0,0,0);
$homegradient-bottom: linear-gradient(
  // to top, 
  white, 
  white
  // $darkGradientColor,
  // $midGradientColor,
  // $lightGradientColor
);
$homegradient-top: linear-gradient(
  to top,
  $darkGradientColor,
  // $midGradientColor,
  $lightGradientColor
);

// Background Colors
$light-background: rgb(218, 218, 218);
$img-background: linear-gradient(
  90deg,
  rgba(186, 186, 186, 1) 0%,
  rgba(111, 111, 111, 1) 41%,
  rgba(55, 55, 55, 1) 94%
);
$dark-background: rgb(0, 0, 0);
$button-background: $nexusBlue;
$navbar-background-color: rgb(248, 248, 248);
$label-background: rgb(217, 240, 241);
$subtitle-background: rgb(0, 119, 255);
$title-background: rgb(228, 228, 228);
$eventcardcolor: rgb(225, 236, 248);
$tab-backgroundcolor: rgb(197, 206, 205);
$navlink-background: rgb(231, 231, 231);

// Text Colors
$button-text-color: rgb(255, 255, 255);
$button-hover-text-color: rgb(0, 0, 0);
$small-text-color: rgb(0, 0, 0);
// $navbar-text-color:rgb(77, 77, 77);
$navbar-text-color: rgb(0, 141, 208);
$label-text-color: rgb(0, 0, 0);
$subtitle-text-color: rgb(255, 255, 255);
$title-text-color: rgb(41, 85, 182);
$h4-text-color: rgb(63, 63, 63);
$navlink-text-color: rgb(0, 144, 228);

// Text Sizes
$small-text-font-size: 13px;
$small-text-font-weight: 400;
$button-text-size: 14px;
$a-text-size: 14px;
$navbar-text-size: 14px;
$label-text-size: 14px;
$label-text-weight: 600;
$subtitle-text-size: 18px;
$title-text-size: 20px;
$h4-text-size: 18px;

//Styles
$button-radius: 5px;
$label-radius: 5px;
$subtitle-radius: 10px;
$title-radius: 5px;
$button-border: transparent;
